import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_GenerateReport = _resolveComponent("GenerateReport")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ReGenerateReport = _resolveComponent("ReGenerateReport")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "reports",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true))
    }, null, 8, ["breadcrumb_home", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TabView, {
        activeIndex: _ctx.self.active_tab,
        "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.active_tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, { header: "Active Reports" }, {
            default: _withCtx(() => [
              (_ctx.self.active_tab == 0)
                ? (_openBlock(), _createBlock(_component_TableView, {
                    key: 0,
                    class: "mt-2",
                    permission_type: "reports",
                    loading: _ctx.self.loading,
                    items: _ctx.self.reports,
                    fields: _ctx.self.table_fields,
                    actions: _ctx.self.action_buttons,
                    pagination: _ctx.self.pagination,
                    disable_select: "",
                    onLoadMoreData: _ctx.getReportListing
                  }, {
                    "col-data": _withCtx(({ data }) => [
                      (data.key == 'progress')
                        ? (_openBlock(), _createBlock(_component_ProgressBar, {
                            key: 0,
                            value: data.value,
                            style: {"height":"20px"}
                          }, null, 8, ["value"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            innerHTML: data.value
                          }, null, 8, _hoisted_2))
                    ]),
                    _: 1
                  }, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, { header: "Future Reports" }, {
            default: _withCtx(() => [
              (_ctx.self.active_tab == 1)
                ? (_openBlock(), _createBlock(_component_TableView, {
                    key: 0,
                    class: "mt-2",
                    permission_type: "reports",
                    loading: _ctx.self.upcoming.loading,
                    items: _ctx.self.upcoming.reports,
                    fields: _ctx.self.upcoming.table_fields,
                    actions: _ctx.self.upcoming.action_buttons,
                    pagination: _ctx.self.upcoming.pagination,
                    disable_select: "",
                    onLoadMoreData: _ctx.getUpcomingReportListing
                  }, {
                    "col-data": _withCtx(({ data }) => [
                      (data.key == 'progress')
                        ? (_openBlock(), _createBlock(_component_ProgressBar, {
                            key: 0,
                            value: data.value,
                            style: {"height":"20px"}
                          }, null, 8, ["value"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            innerHTML: data.value
                          }, null, 8, _hoisted_3))
                    ]),
                    _: 1
                  }, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeIndex"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode("Generate Report ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_GenerateReport, {
            options: _ctx.self.report_options,
            onCreatedReport: _ctx.reloadAllData,
            onSearchUsers: _ctx.searchUsers
          }, null, 8, ["options", "onCreatedReport", "onSearchUsers"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.regenerate_report,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.regenerate_report) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode("Re-Generate Report")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ReGenerateReport, {
            report: _ctx.self.selected_report,
            options: _ctx.self.report_options,
            onUpdatedReport: _ctx.reloadAllData,
            onSearchUsers: _ctx.searchUsers
          }, null, 8, ["report", "options", "onUpdatedReport", "onSearchUsers"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}