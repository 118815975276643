import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditGroup = _resolveComponent("EditGroup")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_CreateGroup = _resolveComponent("CreateGroup")!
  const _component_GroupUserList = _resolveComponent("GroupUserList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "user_groups",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true))
    }, null, 8, ["breadcrumb_home", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "user_groups",
        loading: _ctx.self.loading,
        items: _ctx.self.groups,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getGroupListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        position: "right",
        visible: _ctx.self.edit_group,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.edit_group) = $event))
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Edit User Group")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_EditGroup, {
            group: _ctx.self.selected_group,
            onUpdatedGroup: _ctx.updatedUserListing
          }, null, 8, ["group", "onUpdatedGroup"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        position: "right",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event))
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Add User Group ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_CreateGroup, { onCreatedGroup: _ctx.updatedUserListing }, null, 8, ["onCreatedGroup"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        position: "right",
        class: "w-6",
        visible: _ctx.self.edit_group_users,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.edit_group_users) = $event))
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Update Users In Group ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_GroupUserList, {
            users: _ctx.self.users,
            group: _ctx.self.selected_group,
            loading: _ctx.self.loading,
            onSearchUsers: _ctx.searchUsers,
            onUpdateUserData: _ctx.updateUserData
          }, null, 8, ["users", "group", "loading", "onSearchUsers", "onUpdateUserData"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}