import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "col-9" }
const _hoisted_4 = {
  key: 0,
  class: "profile-detail-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_DetailForm = _resolveComponent("DetailForm")!
  const _component_Card = _resolveComponent("Card")!
  const _component_change_password = _resolveComponent("change-password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TabView, {
        class: "user-detail",
        activeIndex: _ctx.self.active,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.active) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.profile_tabs, (tab, index) => {
            return (_openBlock(), _createBlock(_component_TabPanel, { key: index }, {
              header: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(tab.name), 1),
                _createTextVNode(" " + _toDisplayString(tab.description), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.self.active == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_UserInfo, {
              user_detail: _ctx.user_detail,
              summary_detail: _ctx.summary_detail,
              onImageUploaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('image-uploaded', $event)))
            }, null, 8, ["user_detail", "summary_detail"]),
            _createVNode(_component_Card, { class: "mt-3" }, {
              title: _withCtx(() => [
                _createTextVNode(" Update Your Profile information ")
              ]),
              content: _withCtx(() => [
                _createVNode(_component_DetailForm, {
                  user_detail: _ctx.user_detail,
                  onImageUploaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('image-uploaded', $event))),
                  onUpdatedProfile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updated-profile')))
                }, null, 8, ["user_detail"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.self.active == 1)
        ? (_openBlock(), _createBlock(_component_Card, { key: 1 }, {
            title: _withCtx(() => [
              _createTextVNode(" Update Your password ")
            ]),
            content: _withCtx(() => [
              _createVNode(_component_change_password, {
                onUpdatedProfile: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('updated-profile')))
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}