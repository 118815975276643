import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_DetailView = _resolveComponent("DetailView")!
  const _component_UserProjects = _resolveComponent("UserProjects")!
  const _component_SharedProjects = _resolveComponent("SharedProjects")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_EditUser = _resolveComponent("EditUser")!
  const _component_EditRole = _resolveComponent("EditRole")!
  const _component_SuspendUser = _resolveComponent("SuspendUser")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_CreateUser = _resolveComponent("CreateUser")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "user",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      roles: _ctx.self.roles,
      loading: _ctx.self.loading,
      filter: _ctx.self.filterOptions,
      status_fields: _ctx.self.status_fields,
      suggestions: _ctx.self.suggestions,
      search_placeholder: "Search User",
      add_icon: "fas fa-user-plus",
      onReloadData: _ctx.reloadAllData,
      onSubmitFilterData: _ctx.searchUsers,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true)),
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["breadcrumb_home", "options", "roles", "loading", "filter", "status_fields", "suggestions", "onReloadData", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "user",
        selected_items: _ctx.self.selected_users,
        "onUpdate:selected_items": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.selected_users) = $event)),
        selected_item: _ctx.self.selected_user,
        "onUpdate:selected_item": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.selected_user) = $event)),
        loading: _ctx.self.loading,
        items: _ctx.self.users,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getUserListing
      }, null, 8, ["selected_items", "selected_item", "loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.view_detail,
        "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.view_detail) = $event)),
        position: "right",
        class: "w-6"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_TabView, {
            activeIndex: _ctx.self.active_tab,
            "onUpdate:activeIndex": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.active_tab) = $event)),
            class: "w-12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, null, {
                header: _withCtx(() => [
                  _createTextVNode(" User Detail ")
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, null, {
                header: _withCtx(() => [
                  _createTextVNode(" User Projects ")
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, null, {
                header: _withCtx(() => [
                  _createTextVNode(" Shared With User ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeIndex"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_TabView, {
            activeIndex: _ctx.self.active_tab,
            "onUpdate:activeIndex": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.active_tab) = $event)),
            class: "hidden-header sidebar-tabs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DetailView, {
                    detail: _ctx.self.selected_user,
                    exclude_keys: _ctx.self.exclude_keys
                  }, null, 8, ["detail", "exclude_keys"])
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, null, {
                default: _withCtx(() => [
                  (_ctx.self.active_tab == 1)
                    ? (_openBlock(), _createBlock(_component_UserProjects, {
                        key: 0,
                        user: _ctx.self.selected_user
                      }, null, 8, ["user"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, null, {
                default: _withCtx(() => [
                  (_ctx.self.active_tab == 2)
                    ? (_openBlock(), _createBlock(_component_SharedProjects, {
                        key: 0,
                        user: _ctx.self.selected_user
                      }, null, 8, ["user"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeIndex"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.change_password,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.self.change_password) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Change User Password ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ChangePassword, {
            user: _ctx.self.selected_user,
            onUpdatedPassword: _cache[6] || (_cache[6] = ($event: any) => (_ctx.self.change_password = false))
          }, null, 8, ["user"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_user,
        "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.edit_user) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Edit User ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_EditUser, {
            user: _ctx.self.selected_user,
            userRoles: _ctx.self.roles,
            onEditRolePermission: _ctx.editUserRole,
            onUpdatedUser: _ctx.updatedUserListing
          }, null, 8, ["user", "userRoles", "onEditRolePermission", "onUpdatedUser"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_role,
        "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.self.edit_role) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Permission For " + _toDisplayString(_ctx.self.selected_role[0].name), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_EditRole, {
            role: _ctx.self.selected_role[0],
            onUpdatedRole: _ctx.getUserData
          }, null, 8, ["role", "onUpdatedRole"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.suspend_view,
        "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.self.suspend_view) = $event)),
        modal: "",
        header: "Suspend Reason",
        style: { width: '50vw' },
        breakpoints: { '960px': '75vw', '641px': '100vw' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SuspendUser, {
            user: _ctx.self.selected_user,
            onUserSuspended: _ctx.updatedUserListing
          }, null, 8, ["user", "onUserSuspended"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Add User ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_CreateUser, {
            userRoles: _ctx.self.roles,
            onEditRolePermission: _ctx.editUserRole,
            onCreatedUser: _ctx.updatedUserListing
          }, null, 8, ["userRoles", "onEditRolePermission", "onCreatedUser"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}