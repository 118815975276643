import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ProfileDetail = _resolveComponent("ProfileDetail")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading
    }, null, 8, ["breadcrumb_home", "options", "loading"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.self.loading)
        ? (_openBlock(), _createBlock(_component_Card, {
            key: 0,
            id: "profile-data"
          }, {
            content: _withCtx(() => [
              _createVNode(_component_Toolbar, null, {
                start: _withCtx(() => [
                  _createTextVNode(" Enable two factor authentication to make your account more secure. ")
                ]),
                end: _withCtx(() => [
                  _createVNode(_component_InputSwitch, {
                    onChange: _ctx.updateTwoFa,
                    class: "custom-switch",
                    modelValue: _ctx.self.user_detail.two_factor_auth,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.user_detail.two_factor_auth) = $event))
                  }, null, 8, ["onChange", "modelValue"])
                ]),
                _: 1
              })
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_ProfileDetail, {
                user_detail: _ctx.self.user_detail,
                summary_detail: _ctx.self.summary_detail,
                onImageUploaded: _ctx.onImageUpload,
                onUpdatedProfile: _ctx.reloadProfile
              }, null, 8, ["user_detail", "summary_detail", "onImageUploaded", "onUpdatedProfile"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}