import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "flex align-items-center justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "application_settings",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading
    }, null, 8, ["breadcrumb_home", "options", "loading"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createTextVNode(" These setting will impact behavior of overall system. ")
        ]),
        content: _withCtx(() => [
          _createVNode(_component_Toolbar, null, {
            start: _withCtx(() => [
              _createTextVNode(" Enable notification to users after file upload. ")
            ]),
            end: _withCtx(() => [
              _createVNode(_component_InputSwitch, {
                class: "custom-switch",
                modelValue: _ctx.self.enabled,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.enabled) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              type: "submit",
              label: "Update Settings",
              loading: _ctx.self.updating_setting,
              onClick: _withModifiers(_ctx.updateSetting, ["prevent"]),
              class: "button-primary"
            }, null, 8, ["loading", "onClick"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}