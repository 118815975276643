import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditTemplate = _resolveComponent("EditTemplate")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_CreateTemplate = _resolveComponent("CreateTemplate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "folder_template",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true))
    }, null, 8, ["breadcrumb_home", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "folder_template",
        loading: _ctx.self.loading,
        items: _ctx.self.templates,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getTemplateListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_template,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.edit_template) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Edit Template")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_EditTemplate, {
            template: _ctx.self.selected_template,
            onUpdatedTemplate: _ctx.reloadAllData
          }, null, 8, ["template", "onUpdatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => [
          _createTextVNode(" Add Template")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_CreateTemplate, { onCreatedTemplate: _ctx.reloadAllData }, null, 8, ["onCreatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}